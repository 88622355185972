// Here we only use jquery.slim because we only need jquery to do some DOM operation.
import $jq from 'jquery/dist/jquery';
import moment from 'moment';
import daterangepicker from 'daterangepicker/daterangepicker.js';
// import 'daterangepicker/daterangepicker.css';
import { default as sms_counter } from './js/sms/sms-length';

// TODO: move this to his own file
$jq(function () {
    // Single datetime
    $jq('input.enable_datetime_pickup').daterangepicker(
        {
            // $jq('input[class="pickup_datetime"]').daterangepicker({
            singleDatePicker: true,
            minYear: moment().year(),
            // "maxYear": moment().year(),
            // "showWeekNumbers": true,
            timePicker: true,
            autoApply: true,
            locale: {
                // "format": "DD MMMM YYYY HH:mm",
                format: 'YYYY-MM-DD HH:mm', // format support by Django
                // https://docs.djangoproject.com/en/3.2/ref/settings/#datetime-input-formats
                separator: ' - ',
                applyLabel: 'Apply',
                cancelLabel: 'Cancel',
                fromLabel: 'From',
                toLabel: 'To',
                customRangeLabel: 'Custom',
                weekLabel: 'W',
                firstDay: 1,
            },
            timePicker24Hour: true,
            // "startDate": "06/29/2021",
            // "endDate": "07/05/2021",
            drops: 'up',
        },
        function (start, end, label) {
            console.log('A new date selection was made: ' + start.format('YYYY-MM-DD HH:mm'));
        }
    );

    // Range
    $jq('input.enable_datetime_range_pickup').daterangepicker(
        {
            // $jq('input[class="pickup_datetime"]').daterangepicker({
            singleDatePicker: false,
            minYear: moment().year(),
            // "maxYear": moment().year(),
            // "showWeekNumbers": true,
            timePicker: true,
            autoApply: true,
            locale: {
                // "format": "DD MMMM YYYY HH:mm",
                format: 'YYYY-MM-DD HH:mm', // format support by Django
                // https://docs.djangoproject.com/en/3.2/ref/settings/#datetime-input-formats
                separator: ' - ',
                applyLabel: 'Apply',
                cancelLabel: 'Cancel',
                fromLabel: 'From',
                toLabel: 'To',
                customRangeLabel: 'Custom',
                weekLabel: 'W',
                firstDay: 1,
            },
            timePicker24Hour: true,
            // "startDate": "06/29/2021",
            // "endDate": "07/05/2021",
            drops: 'up',
        },
        function (start, end, label) {
            console.log('A new date selection was made: ' + start.format('YYYY-MM-DD HH:mm'));
        }
    );
});

// SMS textarea counter
// --------------------
// $jq('.textarea_event_counter').on("input", function () {
//   $jq("#textarea_counter").text($jq(this).val().length);
// });

// $jq('.textarea_event_counter').on("paste", function () {
//   console.log("\n\n 1) textarea_event_counter... paste");
// });

$jq('.textarea_event_counter').on('keyup paste', function () {
    console.log('\n\n 1) textarea_event_counter... keyup paste');
    console.log($jq('.textarea_event_counter').val());
    $jq('#textarea_counter').text($jq(this).val().length);
    let result_counter = sms_counter($jq('.textarea_event_counter').val());
    // result_counter = { "encoding": "GSM_7BIT", "length": 6, "characterPerMessage": 160, "inCurrentMessage": 6, "remaining": 154, "messages": 1 }
    console.log(result_counter);
    $jq('.sms_counter__encoding').html(result_counter.encoding);
    $jq('.sms_counter__length').html(result_counter.length);
    $jq('.sms_counter__characterPerMessage').html(result_counter.characterPerMessage);
    $jq('.sms_counter__inCurrentMessage').html(result_counter.inCurrentMessage);
    $jq('.sms_counter__remaining').html(result_counter.remaining);
    $jq('.sms_counter__messages').html(result_counter.messages);
});

// // Document is ready | used when refreshing the page
$jq.when($jq.ready).then(function () {
    console.log('***********');
    // console.log($jq(".textarea_event_counter").text());
    console.log($jq('.textarea_event_counter').val());
    $jq('.textarea_event_counter').trigger('paste');
});

// --------------------

// Function to loop through all the checkbox and add them to the hidden field store_checked_checkboxes
function store_checked_checkboxes() {
    var list_checked_ID = [];
    $('.checkbox:checkbox:checked').each(function (i) {
        list_checked_ID[i] = $(this).val();
    });
    $('#store_checkboxes').attr('value', list_checked_ID);
    $('#selected-item-count-text').text(list_checked_ID.length);
}

$('.checkable-checkbox').on('click', function () {
    store_checked_checkboxes();
});

// Toggle Checkboxes
$jq('#toggle-all-checkboxes').on('click', function (event) {
    $jq('.checkbox').each(function () {
        $jq(this).prop('checked', $jq('#toggle-all-checkboxes').is(':checked'));
    });
    store_checked_checkboxes();
});

// Action Checked Checkboxes
$jq('.action-checked-checkboxes').on('click', function (event) {
    console.log('action-checked-checkboxes... clicked');
    console.log($jq('#CheckedCheckboxForm'));
    console.log($jq(this).data('target-url'));
    $jq('#CheckedCheckboxForm').attr('action', $jq(this).data('target-url'));
    $jq('#CheckedCheckboxForm').submit();
});

// import './styles.scss';
